export const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/front/index.vue'),
        children: [
            // 회원 관련
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Logout.vue')
                    },
                    {
                        path: 'join',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'agree',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/agree.vue')
                            },
                            {
                                path: 'form',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/form.vue')
                            }
                        ]
                    },
                    {
                        path: 'find',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'id',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/id.vue')
                            },
                            {
                                path: 'password',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/password.vue')
                            }
                        ]
                    },
                    {
                        path: 'email_certification',
                        component: () => import(/* webpackChunkName "Auth" */ '@/views/auth/EmailCertification.vue')
                    }
                ]
            },

            // 마이페이지
            {
                path: 'mypage',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'dashboard',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/mypage/dashboard.vue')
                    },
                ]
            },

            // 서비스
            {
                path: 'service',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'book',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/book.vue')
                    },
                    {
                        path: 'search',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/search.vue')
                    },
                    {
                        path: 'text',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/service/text.vue')
                    },
                ]
            },

            // TEST
            {
                path: '/test',
                component: () => import(/* webpackChunkName: "Common" */ '@/tests/index.vue')
            },

            // Home
            {
                path: '/',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/home.vue')
            },
        ]
    },
]